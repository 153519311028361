<template>
  <div class="prodect">
      <div style="height:80px"></div>
    <banner title="产品中心" subTitle="Product Center" />
    <div class="body-box">
      <div class="c-width cf w1200">
        <div class="l-menu fl">
          <p class="menu-name">企业文化</p>
          <p
            v-for="(item, index) in data"
            :key="item.id"
            class="link"
            :class="[item.id == fid ? 'on' : '']"
            @click="selLink({ index }, item.id)"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="r-box fr">
          <div class="r-header cf">
            <h4 class="fl">{{ data[index].title }}</h4>
            <div class="fr path">首页 > 企业文化 > {{ data[index].title }}</div>
          </div>
          <div class="r-context">
             '1231'
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../../product/data/data";
import banner from "@/components/banner";
export default {
  components: {
    banner,
  },
  data() {
    return {
      data: data,
      fid: 1,
      index: 0,
      itemIndex:0
    };
  },
  methods: {
    selLink: function (index, fid) {
      this.index = index.index;
      this.fid = fid;
    },
    teProDetail:function(index){
      this.itemIndex = index.index;
         console.log(index)
    }
  },
};
</script>
<style scoped>
@import '../style.css'
</style>